import $ from 'jquery';
import 'slick-carousel';

const initializeCarousel = function () {
	$('.tweetCarousel, .carousel').slick({
		slidesToShow: 2,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	});
	$('.reviews-carousel').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		infinite: true,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1230,
				settings: {
					slidesToShow: 3,
					autoplay: true,
					autoplaySpeed: 8000, // Time between each slide
				},
			},
			{
				breakpoint: 869,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});
	$('.banner-carousel').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
	});
	$('.wide-review-carousel').slick({
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 8000, // Time between each slide
		speed: 1000,
		pauseOnHover: true,
		pauseOnFocus: true,
		cssEase: 'cubic-bezier(0.22, 1, 0.36, 1)',
		centerMode: true,
	});
};

export default {
	setupReviewCarousel: initializeCarousel,
};
